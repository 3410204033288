import { Controller } from 'stimulus'
import WaveSurfer from 'wavesurfer.js'
import { googleAnalytics3Event } from 'lib/googleAnalytics3'

export default class extends Controller {
  static targets = ['wave', 'progress']
  static values = {
    waveformUrl: String,
    previewUrl: String,
    playing: Boolean,
    itemIdForGa3Events: String,
    itemTypeForGa3Events: String,
    waveColor: String,
    loaded: Boolean
  }

  connect () {
    // We don't want to do this expensive WaveSurver work on the turbo in-memory cached version.
    // We wait a few more milliseconds for the network request to load, then we do the real initialization.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.handleCleanupOnAnotherPlay = this.handleCleanupOnAnotherPlay.bind(this)

    // All available options are here: https://wavesurfer-js.org/docs/options.html
    this.wavesurfer = WaveSurfer.create({
      container: this.waveTarget,
      barWidth: 1,
      barHeight: 0.005,
      barMinHeight: 1,
      barGap: 2,
      fillParent: true, // Helps with responsiveness
      hideScrollbar: true,
      interact: false,
      pixelRatio: 2,
      scrollParent: false,
      backend: 'MediaElement', // This allows the browser to start playing straight away.
      normalize: false,
      waveColor: this.waveColorValue, // We set the opacity in CSS because rgba() doesn't work here
      progressColor: 'transparent', // Keep all progress transparent so we can style with CSS background slider
      loaderColor: 'transparent',
      cursorColor: 'transparent',
      backgroundColor: 'transparent',
      responsive: true,
      autoCenter: true,
      height: 100
    })

    // We now do an async fetch and json parse of the waveform data, once we've successfully
    // obtained the waveform json data we load this instance of wavesurfer.
    fetch(this.waveformUrlValue).then(apiResult => {
      apiResult.json().then(jsonResult => {
        // Successful json waveform is received, time to load this instance of wavesurfer with that data:
        this.wavesurfer.load(this.previewUrlValue, jsonResult.data, 'none')

        // Hook into wavesurfer ready state, so we can pull out the full MP3 duration.
        // We could optionally get this from the server side json response but this works too and is less pre-processing.
        this.wavesurfer.on('ready', () => {
          this.wavesurfer.backend.params.interact = true
          this.trackLength = this.wavesurfer.backend.getDuration()
        })

        // Hook into wavesurfer finish event, when the track has finished playing.
        // Simply set the state back to paused
        this.wavesurfer.on('finish', () => {
          this.playingValue = false
        })

        // Hook into wavesurfer pause event, to capture keyboard pausing.
        this.wavesurfer.on('pause', () => {
          this.playingValue = false
        })

        // Hook into wavesurfer play event, to capture keyboard play button.
        this.wavesurfer.on('play', () => {
          this.playingValue = true
        })

        // Hook into seek event, which happens when user clicks on progress bar to jump ahead/back.
        // We get a percentage value here which we can use to update our 'progress' state, this allows us to
        // animate the fancy background colour changing slider with CSS.
        this.wavesurfer.on('seek', (seekPercentage) => {
          this.setProgressBar(seekPercentage * 100)
          if (!this.playingValue) {
            // If the user has seeked to an audio player that isn't playing, we make it start playing from that pos
            this.handleTogglePlay()
          }
        })

        // Hook into audioprocess event, which fires constantly as the track is played.
        // We use this to animate the CSS progress background colour of the widget.
        this.wavesurfer.backend.on('audioprocess', (time) => {
          this.setProgressBar(parseInt((time / this.trackLength) * 1000, 10) / 10)
        })
      }).catch(errorMessage => {
        // We get here if we receive an invalid JSON from the waveform response

      })
    }).catch(errorMessage => {
      // We get here if we receive an invalid API response

    })
  }

  userInteraction () {
    // When setting preload to "none" we're unable to interact with the audio waveform
    // If we try to, we get an error: Failed to set the 'currentTime' property on 'HTMLMediaElement'
    // So we listen for a user interaction on the waveform and treat that the same as a "play" button click.
    if (!this.playingValue) {
      this.handleTogglePlay()
    }
  }

  disconnect () {
    if (this.wavesurfer) {
      this.wavesurfer.destroy()
    }
    if (window.currentWaveSurferCleanup && window.currentWaveSurferCleanup === this.handleCleanupOnAnotherPlay) {
      // if we've left a cleanup function on `window`, delete it before the reference to `this` gets stale
      delete window.currentWaveSurferCleanup
    }
  }

  setProgressBar (value) {
    this.progressTarget.style.left = `${value}%`
  }

  handleTogglePlay () {
    if (!this.playingValue) {
      // The user wants to play this track. First we check if any other AudioPlayers are playing, based on the global window variable:
      if (window.currentWaveSurferCleanup && window.currentWaveSurferCleanup !== this.handleCleanupOnAnotherPlay) {
        // if another player was active we tell it to clean up before playing this instance.
        window.currentWaveSurferCleanup()
      }

      // Once we've finished (optionally) pausing another player above, we can play this instance of wavesurfer.
      const playPromise = this.wavesurfer.play()
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // We now mount this local handleCleanupOnAnotherPlay in window so another instance can pause us if they choose to play.
          window.currentWaveSurferCleanup = this.handleCleanupOnAnotherPlay
          if (!this.loadedValue) {
            this.loadedValue = true
            this.logAudioPreviewEvent()
          }
          this.playingValue = true
        }).catch((error) => {
          if (error.name === 'NotAllowedError') {
            // We ignore these errors caused by certain ios devices.
            // See: https://rollbar.com/envato/mixkit/items/9981/
          } else {
            throw error
          }
        })
      }
    } else {
      // The current instance is playing, so we pause it.
      delete window.currentWaveSurferCleanup
      if (this.playingValue) {
        // Only pause if audio is actually playing
        // Re: https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        this.wavesurfer.pause()
      }
      this.playingValue = false
    }
  }

  /**
   * This is a function that can be called by ANOTHER AudioPlayer when it decides to take over audio playback.
   * This function is available in a global window scope, see below for how this is triggered.
   */
  handleCleanupOnAnotherPlay () {
    this.wavesurfer && this.playingValue && this.wavesurfer.pause()
  }

  logAudioPreviewEvent () {
    googleAnalytics3Event({
      eventAction: 'play',
      eventCategory: `${this.itemTypeForGa3EventsValue} Preview`,
      eventLabel: this.itemIdForGa3EventsValue
    })
  }
}
