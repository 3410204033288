import Cookies from 'js-cookie'
import { Controller } from 'stimulus'
import { Consent, deferRun, userHasOptedOutOfCookiesForCategory } from 'lib/consent'
import { getCookieDomains } from 'lib/getCookieDomains'

export default class extends Controller {
  static values = {
    cookiebotEnabled: Boolean,
    microsoftClarityEnabled: Boolean
  }

  connect () {
    // We don't want to do this when displaying the turbo in-memory cached version.
    // After a few more milliseconds the network request will finish,
    // and this connect() will run again without this preview attribute set.
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    // If we've disabled Clarity for whatever reason (e.g. local dev) then we return early
    if (!this.microsoftClarityEnabledValue) return

    const allowClarityCookies = () => {
      window.clarity('consent')
    }

    if (this.cookiebotEnabledValue) {
      // We use our handy deferRun() helper to run this callback when we know the user's cookie preferences.
      // This will allow MS Clarity to use cookies straight away if we already have consent, or wait for consent before using them.
      // Clarity will still run and collect data either way.
      deferRun(allowClarityCookies, Consent.statistics)
    } else {
      // Cookiebot is disabled, we just allow MS Clarity to use cookies straight away:
      allowClarityCookies()
    }

    if (typeof window !== 'undefined') {
      this.handleCookiebotAcceptDeclineEventClarity = this.handleCookiebotAcceptDeclineEventClarity.bind(this)
      window.addEventListener('CookiebotOnAccept', this.handleCookiebotAcceptDeclineEventClarity)
      window.addEventListener('CookiebotOnDecline', this.handleCookiebotAcceptDeclineEventClarity)
    }
  }

  // On declining statistics consent, we delete Clarity-related cookies
  handleCookiebotAcceptDeclineEventClarity () {
    if (userHasOptedOutOfCookiesForCategory(Consent.statistics)) {
      // Manually delete any existing Clarity cookies
      ;['_clck', '_clsk', 'CLID', 'ANONCHK', 'MR', 'MUID', 'SM'].forEach((cookieName) => {
        // Clarity sets some cookies on the full current domain and some on the apex domain
        // (e.g. some on www.example.com and some on example.com) so we loop through both to delete all cookies
        getCookieDomains().forEach((cookieDomain) => {
          Cookies.remove(cookieName, { path: '/', domain: cookieDomain })
        })
      })
    }
  }
}
